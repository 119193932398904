@import url('https://fonts.googleapis.com/css?family=Lato:300,400,700,900|Skranji&display=swap');

body {
  margin: 0;
  font-family: "Comic Neue", cursive;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #333338;
  letter-spacing: 0.5px;
  overflow-x: hidden;
  overflow-y: auto;
}

*{
  box-sizing: border-box;
}

a,a:hover{
  color: inherit;
  text-decoration: none;
}
